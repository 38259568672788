import React from "react"
import { graphql, Link } from "gatsby"
import _ from "lodash"
import { DiscussionEmbed } from "disqus-react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "components/PostCard/PostCard"
import PostDetails from "components/PostDetails/PostDetails"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  RedditShareButton,
} from "react-share"
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoPinterest,
  IoLogoReddit,
} from "react-icons/io"
import {
  BlogPostDetailsWrapper,
  RelatedPostWrapper,
  RelatedPostItems,
  RelatedPostTitle,
  RelatedPostItem,
  BlogPostFooter,
  PostShare,
  PostTags,
  BlogPostComment,
} from "./templates.style"

const BlogPostTemplate = (props: any) => {
  console.log('props', props);

  const post = props.data.contentfulPost
  const { edges } = props.data.allContentfulPost
  const title = post.title
  const slug = post.slug

  const disqusConfig = {
    shortname: process.env.DISQUS_NAME,
    config: { identifier: slug, title },
  }
  return (
    <Layout>
      <SEO
        title={post.title}
        description={post.summary}
      />
      <BlogPostDetailsWrapper>
        <PostDetails
          title={post.title}
          date={post.updatedAt}
          preview={
            post.cover == null
              ? null
              : post.cover.fluid
          }
          description={documentToReactComponents(post.content.json)}
        />

        <BlogPostFooter>
          {post.tags == null ? null : (
            <PostTags className="post_tags">
              {post.tags.map((tag: string, index: number) => (
                <Link key={index} to={`/tags/${_.kebabCase(tag)}/`}>
                  {`#${tag}`}
                </Link>
              ))}
            </PostTags>
          )}
          <PostShare>
            <span>Share This:</span>
            <FacebookShareButton
              url={post.slug}
              quote={post.title}
            >
              <IoLogoFacebook />
            </FacebookShareButton>
            <TwitterShareButton
              url={post.slug}
              title={post.title}
            >
              <IoLogoTwitter />
            </TwitterShareButton>
            <PinterestShareButton
              url={post.slug}
              media={`${
                post.cover == null
                  ? null
                  : post.cover.fluid
                }`}
            >
              <IoLogoPinterest />
            </PinterestShareButton>
            <RedditShareButton
              url={post.slug}
              title={`${post.title}`}
            >
              <IoLogoReddit />
            </RedditShareButton>
          </PostShare>
        </BlogPostFooter>
        <BlogPostComment>
          <DiscussionEmbed {...disqusConfig} />
        </BlogPostComment>
      </BlogPostDetailsWrapper>

      {edges.length !== 0 && (
        <RelatedPostWrapper>
          <RelatedPostTitle>Related Posts</RelatedPostTitle>
          <RelatedPostItems>
            {edges.map(({ node }: any) => (
              <RelatedPostItem key={node.slug}>
                <PostCard
                  title={node.title || node.slug}
                  url={node.slug}
                  image={
                    node.cover == null
                      ? null
                      : node.cover.fluid
                  }
                  tags={node.tags}
                />
              </RelatedPostItem>
            ))}
          </RelatedPostItems>
        </RelatedPostWrapper>
      )}
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $tag: [String!]) {
    site {
      siteMetadata {
        title
        author
      }
    }
    allContentfulPost(sort: {fields: updatedAt, order: DESC}, limit: 3, filter: {tags: {in: $tag}, slug: {ne: $slug}}) {
      edges {
        node {
          title
          slug
          tags
          cover {
            fluid(maxWidth: 370, maxHeight: 220, quality: 90) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    contentfulPost(slug: {eq: $slug}) {
      id
      summary {
        summary
      }
      tags
      title
      updatedAt(formatString: "DD MMM, YYYY")
      cover {
        fluid(maxWidth: 1170, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      content {
        json
      }
    }
  }
`
